var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.showMenu
      ? _c("div", { class: ["menu", { top: _vm.top }] }, [
          _c(
            "div",
            {
              class: [
                "menu__content",
                _vm.scrollClass,
                _vm.minHeightClass,
                _vm.startHeightClass,
              ],
            },
            [
              _c("ModalClose", {
                attrs: {
                  "modal-class": "menu__close",
                  "close-event": _vm.closeEvent,
                },
              }),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }