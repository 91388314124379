<template>
    <div>
        <img v-if="loading" src="/img/ui/icon-loader-eclipse.svg" alt="loading" width="50" height="50" class="menu__notifications--loader">
        <div class="menu__header">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <circle cx="20" cy="20" r="20" fill="white" />
                <path d="M22.9675 28.5176C22.7077 28.9682 22.3338 29.3426 21.8835 29.6028C21.4331 29.863 20.9222 30 20.402 30C19.8819 30 19.3709 29.863 18.9206 29.6028C18.4702 29.3426 18.0963 28.9682 17.8365 28.5176" fill="white" />
                <path d="M22.9675 28.5176C22.7077 28.9682 22.3338 29.3426 21.8835 29.6028C21.4331 29.863 20.9222 30 20.402 30C19.8819 30 19.3709 29.863 18.9206 29.6028C18.4702 29.3426 18.0963 28.9682 17.8365 28.5176" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M28.6899 25.1511H12C12.8815 23.3611 13.284 21.3731 13.1683 19.3811L13.0729 17.7979C12.9483 16.1603 13.2783 14.52 14.0266 13.058C15.3475 10.6022 17.6889 9 20.3211 9C22.9534 9 25.2947 10.6022 26.6156 13.058C27.3639 14.52 27.694 16.1603 27.5693 17.7979L27.4787 19.3334C27.3593 21.3404 27.7601 23.3444 28.6422 25.1511H28.6899Z" fill="white" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p>Notifications</p>
        </div>

        <div v-if="loading === false" class="menu__notifications--mark-as-read">
            <a href="#" @click.prevent="markAllRead">Mark all as read</a>
            <img v-if="loadingMarkRead" src="/img/ui/icon-loader-eclipse.svg" alt="loading" width="26" height="26" class="menu__notifications--mark-as-read--loader">
        </div>

        <div class="menu__notifications" v-for="(notification, index) in notifications">
            <div v-if="notification.type === NOTIFICATION_TYPES.FOLLOWER_ADDED" :class="['menu__notification', notification.read === false ? 'unread' : '']">
                <div class="menu__notification-img">
                    <img :src="$simpleStore.cloudinaryUrl(80, 80, notification.notifierAvatar, $root)"
                         width="40" height="40" alt="User image"
                    >
                </div>
                <div class="menu__notification-details">
                    <p>
                        <a :href="'/u/' + notification.notifiers[0].username">{{notification.notifiers[0].name}}</a> started following you
                        <span>{{notification.when}}</span>
                    </p>
                </div>
                <div class="c-panel__notification-btn" @click="handleClick($event, index)">
                    <button v-if="notification.isFollowing === false" type="button" class="button__red" data-method="follow" :data-parameter="notification.notifiers[0].user_id">Follow</button>
                    <button v-if="notification.isFollowing === true" type="button" class="button__blue" data-method="unfollow" :data-parameter="notification.notifiers[0].user_id">
                        <svg width="36" height="18" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 16.6772C20.4017 15.1406 21.322 13.7725 22.6191 12.7837C23.9162 11.7948 25.5181 11.2401 27.1782 11.2049C28.8383 11.1697 30.4646 11.656 31.8064 12.5888C33.1483 13.5217 34.1314 14.8494 34.6044 16.3675" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M27.6678 8.17969H26.9352C26.0173 8.17969 25.137 7.83192 24.4879 7.21288C23.8389 6.59385 23.4742 5.75425 23.4742 4.8788V4.57667C23.4742 3.6281 23.8693 2.71836 24.5726 2.04762C25.2759 1.37687 26.2297 1.00006 27.2243 1.00006C27.7377 0.999565 28.2462 1.09566 28.7206 1.28281C29.1951 1.46997 29.6262 1.74452 29.9892 2.09077C30.3522 2.43701 30.6401 2.84814 30.8364 3.30063C31.0326 3.75311 31.1333 4.23806 31.1328 4.72772V4.8788C31.1328 5.75425 30.7682 6.59385 30.1191 7.21288C29.47 7.83192 28.5897 8.17969 27.6718 8.17969H27.6678Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 9.48387L5.30769 14L13 4" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                    </button>
                </div>
            </div>

            <div :class="['menu__notification', notification.read === false ? 'unread' : '']" v-else-if="notification.type === NOTIFICATION_TYPES.LIKE_ADDED">
                <div class="menu__notification-img">
                    <div class="menu__notification-img--two">
                        <img v-for="notifier in notification.notifiers"
                             :src="$simpleStore.cloudinaryUrl(64, 64, notifier.avatar, $root)"
                             width="32" height="32" alt="User image">
                    </div>
                    <img :src="'/img/communities/' + notification.communityId + '/' + notification.communityThumbnail"
                         height="20" width="20" class="logo" :alt="notification.communityName"
                    >
                </div>
                <div class="menu__notification-details">
                    <p>
                        <template v-for="(notifier, index) in notification.notifiers">
                            <template v-if="index > 0 && notification.notifiers.length === 2">
                                and
                            </template>
                            <template v-else-if="index === 1 && notification.notifiers.length > 2">
                                ,
                            </template>
                            <a :href="'/u/' + notifier.username">{{notifier.name}}</a>
                        </template>
                        likes your post in
                        <a :href="'/' + notification.communityUrl">{{notification.communityName}}</a>
                        <span>{{notification.when}}</span>
                    </p>
                </div>
                <div class="menu__notification-content" v-if="notification.thumbnail != null">
                    <img :src="$simpleStore.cloudinaryUrl(100, 100, notification.thumbnail, $root)"
                         height="50" width="50" alt="Pic"
                    >
                </div>
            </div>

            <template v-else-if="notification.type === NOTIFICATION_TYPES.UGC_ADDED">
                <div :class="['menu__notification', notification.read === false ? 'unread' : '']">
                    <div class="menu__notification-img">
                        <img :src="$simpleStore.cloudinaryUrl(40, 40, notification.notifierAvatar, $root)" width="40" height="40" alt="User image">
                    </div>
                    <div class="menu__notification-details">
                        <p>
                            <a :href="'/u/' + notification.notifiers[0].username">{{notification.notifiers[0].name}}</a> posted in the
                            <a :href="'/' + notification.communityUrl">{{notification.communityName}}</a> community
                            <span>{{notification.when}}</span>
                        </p>
                    </div>
                </div>

                <div class="menu__notification feature">
                    <div class="menu__notification-details">
                        <p>
                            {{notification.article.title}}
                        </p>
                    </div>
                    <div class="menu__notification-content" v-if="notification.article.thumbnail != null">
                        <img :src="$simpleStore.cloudinaryUrl(100, 100, notification.article.thumbnail, $root)"
                             height="50" alt="Pic"
                        >
                    </div>
                </div>
            </template>
        </div>

<!--        <div class="menu__notifications">
            <h4>Older</h4>
            <div class="menu__notification accredited">
                <div class="menu__notification-img">
                    <img src="/img/communities/6944/main.png" width="40" height="40" alt="Juventus">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
                        <path d="M5.71595 6.702L6.42218 6.95446L5.71595 6.702C5.69491 6.76085 5.65259 6.80968 5.59734 6.83888L3.41495 7.99209C2.7126 8.36322 2.34833 9.16086 2.52779 9.9347L3.08541 12.3392C3.09953 12.4001 3.09033 12.4641 3.05964 12.5185L1.84717 14.6685L2.50045 15.0369L1.84717 14.6685C1.45696 15.3605 1.58176 16.2284 2.15109 16.7824L2.67412 16.2449L2.15109 16.7824L3.92018 18.5037C3.96497 18.5473 3.99182 18.6061 3.99542 18.6685L4.13782 21.1327C4.18365 21.9258 4.75789 22.5885 5.53634 22.7467L7.95522 23.2383C8.01647 23.2508 8.07083 23.2857 8.10759 23.3363L9.55965 25.3323C10.027 25.9747 10.8683 26.2218 11.6087 25.934L13.9094 25.0398C13.9677 25.0172 14.0323 25.0172 14.0906 25.0398L16.3913 25.934C17.1317 26.2218 17.973 25.9747 18.4403 25.3323L19.8924 23.3363C19.9292 23.2857 19.9835 23.2508 20.0448 23.2383L22.4637 22.7467C23.2421 22.5885 23.8163 21.9258 23.8622 21.1327L24.0046 18.6685C24.0082 18.6061 24.035 18.5473 24.0798 18.5037L25.8489 16.7824C26.4182 16.2284 26.543 15.3605 26.1528 14.6685L24.9404 12.5185C24.9097 12.4641 24.9005 12.4001 24.9146 12.3392L25.4722 9.9347C25.6517 9.16086 25.2874 8.36322 24.5851 7.99209L22.4027 6.83888C22.3474 6.80968 22.3051 6.76085 22.2841 6.702L21.4532 4.3777C21.1858 3.62969 20.4481 3.15561 19.6566 3.22311L17.1972 3.43286C17.1349 3.43817 17.0729 3.41996 17.0234 3.38183L15.0678 1.87572C14.4384 1.39102 13.5616 1.39102 12.9322 1.87572L10.9766 3.38183C10.9271 3.41996 10.8651 3.43817 10.8028 3.43286L8.34341 3.22311C7.55191 3.15561 6.81423 3.62969 6.54683 4.3777L5.71595 6.702Z" fill="#DB2848" stroke="#F3F3F3" stroke-width="1.5" />
                        <path d="M11 13.7419L13.5128 16L18 11" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </div>
                <div class="menu__notification-details">
                    <p>
                        You are now an accredited member in
                        <a href="#">Juventus</a> <span>1 day ago</span>
                    </p>
                </div>
            </div>

            <div class="menu__notification">
                <div class="menu__notification-img">
                    <img src="/img2.0/avatar-example.png" width="40" height="40" alt="User image">
                </div>
                <div class="menu__notification-details">
                    <p>
                        <a href="#">Barry Evans</a> invited you to join
                        the Juventus community
                    </p>
                </div>
                <div class="menu__notification-content">
                    <a href="#" class="button__red">Join</a>
                </div>
            </div>

            <div class="menu__notification">
                <div class="menu__notification-img">
                    <img src="/img2.0/avatar-example.png" width="40" height="40" alt="User image">
                </div>
                <div class="menu__notification-details">
                    <p>
                        <a href="#">Dyadey has removed your post</a> in
                        the <a href="#">Juventus</a> community due to
                        copyright infringement <span>1 day ago</span>
                    </p>
                </div>
            </div>

            <div class="menu__notification repost-single">
                <div class="menu__notification-img">
                    <img src="/img2.0/avatar-example.png" width="40" height="40" alt="User image">
                    <div class="menu__notification-img&#45;&#45;repost">
                        <img src="/img/communities/6944/main.png" height="20" width="20" class="logo" alt="Juventus">
                        <img src="/img/communities/6944/main.png" height="20" width="20" class="logo" alt="Juventus">
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                        <path d="M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M14 1L11 4L14 7" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 15L12 12L9 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="menu__notification-details">
                    <p>
                        <a href="#">Luke Carré</a> reposted your
                        <a href="#">Juventus</a> post into the
                        <a href="#">Serie A</a> Community
                        <span>1 day ago</span>
                    </p>
                </div>
                <div class="menu__notification-content">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Shaqi_jrvej.jpg" height="50" width="50" alt="Pic">
                </div>
            </div>

            <div class="menu__notification repost-multiple">
                <div class="menu__notification-img">
                    <div class="menu__notification-img&#45;&#45;three">
                        <img src="/img2.0/avatar-example.png" width="30" height="30" alt="User image">
                        <img src="/img2.0/avatar-example.png" width="30" height="30" alt="User image">
                        <img src="/img2.0/avatar-example.png" width="30" height="30" alt="User image">
                    </div>
                    <div class="menu__notification-img&#45;&#45;repost">
                        <img src="/img/communities/6944/main.png" height="20" width="20" class="logo" alt="Juventus">
                        <img src="/img/communities/6944/main.png" height="20" width="20" class="logo" alt="Juventus">
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                        <path d="M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M14 1L11 4L14 7" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 15L12 12L9 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="menu__notification-details">
                    <p>
                        <a href="#">Luke Carré</a> and
                        <a href="#">7 others</a> reposted your
                        <a href="#">Juventus</a> post into the
                        <a href="#">Serie A</a> Community
                        <span>1 day ago</span>
                    </p>
                </div>
                <div class="menu__notification-content">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Shaqi_jrvej.jpg" height="50" width="50" alt="Pic">
                </div>
            </div>
        </div>-->
    </div>
</template>
<script>

export default {
    data() {
        return {
            NOTIFICATION_TYPES: {
                FOLLOWER_ADDED: 1,
                FOLLOWER_REMOVED: 2,
                LIKE_ADDED: 3,
                ANOTHER: 4,
                UGC_ADDED: 5
            },
            loading: true,
            loadingMarkRead: false,
            notifications: []
        }
    },
    created() {
        if (this.$root.user === false) {
            return false;
        }
        this.$http.post('/api/v1/notifications').then(response => {
            this.notifications = response.body;
            this.loading = false;
        }, response => {
            console.log('API error: ', response.body.message);
        });
    },
    methods: {
        handleClick(e, index) {
            // Call method dynamically if button click
            // method name set on button with data-method and data-parameter attributes on <button>
            let target = e.target;
            if (target.tagName.toUpperCase() === 'SVG') {
                target = e.target.parentElement;
            }
            if (target.tagName.toUpperCase() === 'BUTTON') {
                this.buttonDiv = target.parentElement;
                console.log(this.buttonDiv, target.dataset.method, target.dataset.parameter);
                this[target.dataset.method](target.dataset.parameter);
            }
        },
        follow(userId) {
            this.$simpleStore.follow(this, userId, false);
            let button = document.createElement('BUTTON');
            button.setAttribute('class', 'button__blue');
            button.setAttribute('data-method', 'unfollow');
            button.setAttribute('data-parameter', userId);
            button.innerHTML = '<svg width="36" height="18" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M20 16.6772C20.4017 15.1406 21.322 13.7725 22.6191 12.7837C23.9162 11.7948 25.5181 11.2401 27.1782 11.2049C28.8383 11.1697 30.4646 11.656 31.8064 12.5888C33.1483 13.5217 34.1314 14.8494 34.6044 16.3675" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n' +
                '<path d="M27.6678 8.17969H26.9352C26.0173 8.17969 25.137 7.83192 24.4879 7.21288C23.8389 6.59385 23.4742 5.75425 23.4742 4.8788V4.57667C23.4742 3.6281 23.8693 2.71836 24.5726 2.04762C25.2759 1.37687 26.2297 1.00006 27.2243 1.00006C27.7377 0.999565 28.2462 1.09566 28.7206 1.28281C29.1951 1.46997 29.6262 1.74452 29.9892 2.09077C30.3522 2.43701 30.6401 2.84814 30.8364 3.30063C31.0326 3.75311 31.1333 4.23806 31.1328 4.72772V4.8788C31.1328 5.75425 30.7682 6.59385 30.1191 7.21288C29.47 7.83192 28.5897 8.17969 27.6718 8.17969H27.6678Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />\n' +
                '<path d="M1 9.48387L5.30769 14L13 4" stroke="white" stroke-width="1.5" stroke-linecap="round" />\n' +
                '</svg>';
            this.buttonDiv.replaceChild(button, this.buttonDiv.childNodes[0]);
        },
        unfollow(userId) {
            this.$simpleStore.unfollow(this, userId, false);
            let button = document.createElement('BUTTON');
            button.setAttribute('class', 'button__red');
            button.setAttribute('data-method', 'follow');
            button.setAttribute('data-parameter', userId);
            button.textContent = 'Follow';
            this.buttonDiv.replaceChild(button, this.buttonDiv.childNodes[0]);
        },
        markAllRead() {
            this.loadingMarkRead = true;
            this.$http.post('/api/v1/notifications/read').then(response => {
                for (let i = 0; i < this.notifications.length; i++) {
                    this.notifications[i].read = true;
                }
                this.loadingMarkRead = false;
            }, response => {
                console.log('API error: ', response.body.message);
            });
        }
    }
}
</script>
