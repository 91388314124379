var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" }, on: { click: _vm.outsideClick } },
    [
      _c(
        "div",
        {
          class: [
            _vm.showMessageBar ? "active" : "",
            "page__head__message",
            _vm.messageType,
          ],
        },
        [
          _vm._l(_vm.messages, function (message) {
            return _c("p", {
              attrs: { "data-cy": "flash-message" },
              domProps: { innerHTML: _vm._s(message) },
            })
          }),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "button-close", on: { click: _vm.closeMessage } },
            [
              _c("img", {
                attrs: {
                  src: "/img/ui/icon-close.svg",
                  alt: "Close",
                  width: "14",
                  height: "14",
                },
              }),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "header",
        { ref: "pageHead", staticClass: "page__head content" },
        [
          [_vm._m(0)],
          _vm._v(" "),
          _vm.user !== false
            ? [
                _c("div", { staticClass: "page__head__links" }, [
                  _c(
                    "a",
                    {
                      class: _vm.homeActive ? "current" : "",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.home.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "40",
                            height: "40",
                            viewBox: "0 0 40 40",
                            fill: "none",
                          },
                        },
                        [
                          _c("circle", {
                            attrs: {
                              cx: "20",
                              cy: "20",
                              r: "20",
                              fill: "#F3F3F3",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M12.75 17.2518C12.75 16.868 12.9263 16.5054 13.2282 16.2685L19.2282 11.5592C19.6813 11.2036 20.3187 11.2036 20.7718 11.5592L26.7718 16.2685C27.0737 16.5054 27.25 16.868 27.25 17.2518V26C27.25 26.6904 26.6904 27.25 26 27.25H24C23.3096 27.25 22.75 26.6904 22.75 26V23C22.75 22.0335 21.9665 21.25 21 21.25H19C18.0335 21.25 17.25 22.0335 17.25 23V26C17.25 26.6904 16.6904 27.25 16 27.25H14C13.3096 27.25 12.75 26.6904 12.75 26V17.2518Z",
                              stroke: "black",
                              "stroke-width": "1.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "notification active",
                      attrs: { href: "#", "data-tour-mobile": "notifications" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleNotificationMenu.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "40",
                            height: "40",
                            viewBox: "0 0 40 40",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("circle", {
                            attrs: {
                              cx: "20",
                              cy: "20",
                              r: "20",
                              fill: "#F3F3F3",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M22.9669 28.5176C22.7071 28.9682 22.3332 29.3425 21.8829 29.6027C21.4325 29.8629 20.9215 29.9999 20.4014 29.9999C19.8813 29.9999 19.3703 29.8629 18.92 29.6027C18.4696 29.3425 18.0957 28.9682 17.8359 28.5176",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M28.6899 25.1511H12C12.8815 23.3611 13.284 21.3731 13.1683 19.3811L13.0729 17.7979C12.9483 16.1603 13.2783 14.52 14.0266 13.058C15.3475 10.6022 17.6889 9 20.3211 9C22.9534 9 25.2947 10.6022 26.6156 13.058C27.3639 14.52 27.694 16.1603 27.5693 17.7979L27.4787 19.3334C27.3593 21.3404 27.7601 23.3444 28.6422 25.1511H28.6899Z",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: _vm.exploreActive ? "current" : "",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.explore.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "40",
                            height: "40",
                            viewBox: "0 0 40 40",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("circle", {
                            attrs: {
                              cx: "20",
                              cy: "20",
                              r: "20",
                              fill: "#F3F3F3",
                            },
                          }),
                          _vm._v(" "),
                          _c("circle", {
                            attrs: {
                              cx: "20",
                              cy: "20",
                              r: "10.25",
                              stroke: "black",
                              "stroke-width": "1.5",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M14.0125 14.3479C13.905 14.133 14.133 13.905 14.3479 14.0125L22.0331 17.8551C22.0815 17.8793 22.1207 17.9185 22.1449 17.9669L25.9875 25.6521C26.095 25.867 25.867 26.095 25.6521 25.9875L17.9669 22.1449C17.9185 22.1207 17.8793 22.0815 17.8551 22.0331L14.0125 14.3479Z",
                              stroke: "black",
                              "stroke-width": "1.5",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M18 22L22 18",
                              stroke: "black",
                              "stroke-width": "1.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "#" } }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "40",
                          height: "40",
                          viewBox: "0 0 40 40",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "20",
                            cy: "20",
                            r: "20",
                            fill: "#F3F3F3",
                          },
                        }),
                        _vm._v(" "),
                        _c("circle", {
                          attrs: {
                            cx: "17.8571",
                            cy: "17.8571",
                            r: "6.10714",
                            stroke: "black",
                            "stroke-width": "1.5",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M22.4277 22.4287L26.9992 27.0001",
                            stroke: "black",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/post/create" +
                          (typeof _vm.$root.community != "undefined" &&
                          typeof _vm.$root.community.url != "undefined"
                            ? "/" + _vm.$root.community.url
                            : ""),
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "40",
                            height: "40",
                            viewBox: "0 0 40 40",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("circle", {
                            attrs: {
                              cx: "20",
                              cy: "20",
                              r: "20",
                              fill: "#F3F3F3",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M20 12V28",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M28 20L12 20",
                              stroke: "black",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: [
                        "page__head__avatar",
                        _vm.$route.path === "/settings" ? "current" : "",
                      ],
                      attrs: { href: "/u/" + _vm.$root.user.username },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.$root.user.avatar,
                          width: "40",
                          height: "40",
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "c-page-head__account-links logged-out" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/search",
                          "data-tour-mobile": "search",
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "40",
                              height: "40",
                              viewBox: "0 0 40 40",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "20",
                                cy: "20",
                                r: "20",
                                fill: "#F3F3F3",
                              },
                            }),
                            _vm._v(" "),
                            _c("circle", {
                              attrs: {
                                cx: "17.8571",
                                cy: "17.8571",
                                r: "6.10714",
                                stroke: "black",
                                "stroke-width": "1.5",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M22.4277 22.4287L26.9992 27.0001",
                                stroke: "black",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showMemberPanel.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "40",
                              height: "40",
                              viewBox: "0 0 40 40",
                              fill: "none",
                            },
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "20",
                                cy: "20",
                                r: "20",
                                fill: "#DB2848",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M13 28C13.4126 26.3157 14.3578 24.8162 15.69 23.7324C17.0223 22.6486 18.6676 22.0405 20.3727 22.002C22.0778 21.9634 23.748 22.4964 25.1262 23.5188C26.5045 24.5413 27.5142 25.9966 28 27.6605",
                                stroke: "white",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M20.3806 19H19.6153C18.6565 19 17.7369 18.6125 17.0589 17.9227C16.3809 17.233 16 16.2974 16 15.3219V14.9853C16 13.9283 16.4127 12.9146 17.1473 12.1673C17.882 11.4199 18.8783 11 19.9173 11C20.4536 10.9994 20.9847 11.1065 21.4803 11.3151C21.9759 11.5236 22.4262 11.8295 22.8054 12.2153C23.1846 12.6011 23.4853 13.0593 23.6903 13.5634C23.8953 14.0676 24.0005 14.608 24 15.1536V15.3219C24 16.2974 23.6191 17.233 22.9411 17.9227C22.2631 18.6125 21.3435 19 20.3847 19H20.3806Z",
                                stroke: "white",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content" },
        [_c("router-view", { attrs: { name: "index" } })],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "page__foot" }, [
        _c("ul", [
          _vm.user !== false
            ? _c("li", [
                _c(
                  "a",
                  {
                    class: _vm.homeActive ? "current" : "",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.home.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "40",
                          height: "40",
                          viewBox: "0 0 40 40",
                          fill: "none",
                        },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "20",
                            cy: "20",
                            r: "20",
                            fill: "#F3F3F3",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M12.75 17.2518C12.75 16.868 12.9263 16.5054 13.2282 16.2685L19.2282 11.5592C19.6813 11.2036 20.3187 11.2036 20.7718 11.5592L26.7718 16.2685C27.0737 16.5054 27.25 16.868 27.25 17.2518V26C27.25 26.6904 26.6904 27.25 26 27.25H24C23.3096 27.25 22.75 26.6904 22.75 26V23C22.75 22.0335 21.9665 21.25 21 21.25H19C18.0335 21.25 17.25 22.0335 17.25 23V26C17.25 26.6904 16.6904 27.25 16 27.25H14C13.3096 27.25 12.75 26.6904 12.75 26V17.2518Z",
                            stroke: "black",
                            "stroke-width": "1.5",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.user !== false
            ? _c("li", [
                _c(
                  "a",
                  {
                    class: _vm.exploreActive ? "current" : "",
                    attrs: { href: "#", "data-tour-mobile": "explore" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.explore.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "40",
                          height: "40",
                          viewBox: "0 0 40 40",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "20",
                            cy: "20",
                            r: "20",
                            fill: "#F3F3F3",
                          },
                        }),
                        _vm._v(" "),
                        _c("circle", {
                          attrs: {
                            cx: "20",
                            cy: "20",
                            r: "10.25",
                            stroke: "black",
                            "stroke-width": "1.5",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M14.0125 14.3479C13.905 14.133 14.133 13.905 14.3479 14.0125L22.0331 17.8551C22.0815 17.8793 22.1207 17.9185 22.1449 17.9669L25.9875 25.6521C26.095 25.867 25.867 26.095 25.6521 25.9875L17.9669 22.1449C17.9185 22.1207 17.8793 22.0815 17.8551 22.0331L14.0125 14.3479Z",
                            stroke: "black",
                            "stroke-width": "1.5",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M18 22L22 18",
                            stroke: "black",
                            "stroke-width": "1.5",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.user !== false
            ? _c(
                "li",
                [
                  _vm.publishButton === false
                    ? [
                        _c(
                          "a",
                          {
                            ref: "mobileCreate",
                            attrs: {
                              href:
                                "/post/create" +
                                (typeof _vm.$root.community != "undefined" &&
                                typeof _vm.$root.community.url !== "undefined"
                                  ? "/" + _vm.$root.community.url
                                  : ""),
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "40",
                                  height: "40",
                                  viewBox: "0 0 40 40",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("circle", {
                                  attrs: {
                                    cx: "20",
                                    cy: "20",
                                    r: "20",
                                    fill: "#F3F3F3",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M20 12V28",
                                    stroke: "black",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M28 20L12 20",
                                    stroke: "black",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "a",
                          {
                            class: [
                              "ugc__publish-bottom",
                              _vm.publishMobileDisabled
                                ? "publish-disabled"
                                : "",
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.publishConfirmation.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "22",
                                  height: "22",
                                  viewBox: "0 0 22 22",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M21 1L1 9.23513L9.23513 12.7645M21 1L12.7649 21L9.23548 12.7649M21 1L9.23548 12.7645",
                                    stroke: "white",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", [_vm._v("Post")]),
                          ]
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.user === false
                ? [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showMemberPanel.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "40",
                              height: "40",
                              viewBox: "0 0 40 40",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "20",
                                cy: "20",
                                r: "20",
                                fill: "#F3F3F3",
                              },
                            }),
                            _vm._v(" "),
                            _c("circle", {
                              attrs: {
                                cx: "17.8571",
                                cy: "17.8571",
                                r: "6.10714",
                                stroke: "black",
                                "stroke-width": "1.5",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M22.4277 22.4287L26.9992 27.0001",
                                stroke: "black",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/search",
                          "data-tour-mobile": "search",
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "40",
                              height: "40",
                              viewBox: "0 0 40 40",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "20",
                                cy: "20",
                                r: "20",
                                fill: "#F3F3F3",
                              },
                            }),
                            _vm._v(" "),
                            _c("circle", {
                              attrs: {
                                cx: "17.8571",
                                cy: "17.8571",
                                r: "6.10714",
                                stroke: "black",
                                "stroke-width": "1.5",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M22.4277 22.4287L26.9992 27.0001",
                                stroke: "black",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "page__foot__avatar" },
            [
              _vm.user === false
                ? [
                    _c(
                      "a",
                      {
                        staticClass: "js-join-convo__cta",
                        attrs: { href: "#", "data-cy": "avatar-login-footer" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showMemberPanel.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "40",
                              height: "40",
                              viewBox: "0 0 40 40",
                              fill: "none",
                            },
                          },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "20",
                                cy: "20",
                                r: "20",
                                fill: "#DB2848",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M13 28C13.4126 26.3157 14.3578 24.8162 15.69 23.7324C17.0223 22.6486 18.6676 22.0405 20.3727 22.002C22.0778 21.9634 23.748 22.4964 25.1262 23.5188C26.5045 24.5413 27.5142 25.9966 28 27.6605",
                                stroke: "white",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M20.3806 19H19.6153C18.6565 19 17.7369 18.6125 17.0589 17.9227C16.3809 17.233 16 16.2974 16 15.3219V14.9853C16 13.9283 16.4127 12.9146 17.1473 12.1673C17.882 11.4199 18.8783 11 19.9173 11C20.4536 10.9994 20.9847 11.1065 21.4803 11.3151C21.9759 11.5236 22.4262 11.8295 22.8054 12.2153C23.1846 12.6011 23.4853 13.0593 23.6903 13.5634C23.8953 14.0676 24.0005 14.608 24 15.1536V15.3219C24 16.2974 23.6191 17.233 22.9411 17.9227C22.2631 18.6125 21.3435 19 20.3847 19H20.3806Z",
                                stroke: "white",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "a",
                      {
                        class:
                          /^\/(settings|u\/)/.test(_vm.$route.path) === true
                            ? "current"
                            : "",
                        attrs: {
                          href: "/u/" + _vm.$root.user.username,
                          "data-tour-mobile": "profile",
                          "data-test": _vm.$router.name,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/img2.0/avatar-example.png",
                            width: "40",
                            height: "40",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "Menu",
        {
          attrs: {
            "show-menu": _vm.showNotificationMenu,
            "scroll-class": "menu__content--scroll",
            "close-event": "bus-notifications-menu-close",
            "start-height-class": "menu__height-500",
          },
        },
        [_c("NotificationsModal")],
        1
      ),
      _vm._v(" "),
      _vm.user === false || _vm.cookiePanel === true
        ? _c(
            "div",
            {
              class: [_vm.showCookiesPanel ? "cookie-consent" : ""],
              attrs: { "data-cy": "panel-join" },
            },
            [
              _c("router-view", {
                attrs: { name: "userPanels" },
                on: { mounted: _vm.userPanelsMounted },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", { attrs: { name: "invitesPanel" } }),
      _vm._v(" "),
      _c("router-view", { attrs: { name: "tour" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "page__head__logo", attrs: { href: "/" } }, [
      _c("img", {
        attrs: {
          src: "/img/logo-dyadey-main.svg",
          alt: "Dyadey",
          width: "139",
          height: "29",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ugc__publish-notification" }, [
      _c("p", [
        _vm._v(
          "\n                    Please add a title and text to your article to post it\n                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }