export default {
    debug: false,
    heroCount: 0,
    Networks: {
        FB: 1,
        TW: 2,
        IN: 3,
        YT: 4,
        DY: 7
    },
    NetworkNames: {
        1: 'facebook',
        2: 'twitter',
        3: 'instagram',
        4: 'youtube',
        7: 'dyadey'
    },
    stats: {
        members: 0,
        likes: 0,
        views: 0
    },
    message: {
        title: '',
        content: '',
        link: '',
        linkText: '',
        show: false,
        showLink: false,
        showCommunity: false,
        X: null,
        Y: null
    },
    sessionTTL: 300,
    success: false,
    imageUpdateRequests: 0,
    showContextMenu: false,
    swNotificationsUrl: '/js/sw-push.js',
    swNotificationsAppcode: 'BHINBD7lMZUIbPLuZokAsh55R_QWS_siIioKtlHXzjBEtWg-5EYJzcQvidb0wZszDDMV0daWJuKtat6c2YBEun4',
    localStorageCacheKeys: {
        'communities': 'user-communities',
        'drafts': 'user-drafts',
        'likes': 'user-likes',
        'ugcposts': 'user-posts'
    },
    localStorageTTL: 30 * 60 * 1000, // 30 minutes
    setStatsMembers(newValue) {
        this.stats.members = this.formatValue(newValue);
        if (this.debug) {
            console.log(this.stats.members)
        }
    },
    setStatsLikes(newValue) {
        this.stats.likes = this.formatValue(newValue);
        if (this.debug) {
            console.log(this.stats.likes)
        }
    },
    setStatsViews(newValue) {
        this.stats.views = this.formatValue(newValue);
        if (this.debug) {
            console.log(this.stats.views)
        }
    },
    setMessageShow(newValue) {
        this.message.show = newValue;
    },
    setMessageShowCommunity(newValue) {
        this.message.showCommunity = newValue;
    },
    setMessageTitle(newValue) {
        this.message.title = newValue;
    },
    setMessageContent(newValue) {
        this.message.content = newValue;
    },
    setMessageX(newValue) {
        this.message.X = newValue;
    },
    setMessageY(newValue) {
        this.message.Y = newValue;
    },
    formatValue(value) {
        let reZero = /^(\d+)\.0$/;
        if (value < 1000) {
            return value;
        }
        if (value < 1000000) {
            let thousands = (value / 1000).toFixed(1);
            thousands = thousands.replace(reZero, '$1');
            return thousands + 'K';
        }
        let millions = (value / 1000000).toFixed(2);
        millions = millions.replace(reZero, '$1');
        return millions + 'M';
    },
    join(vue, communityId) {
        vue.$http.get('/api/v1/communities/join/' + communityId).then(
            (response) => {
                let data = response.data;
                if (data.error === true) {
                    vue.title = 'Error Joining';
                    vue.message = data.message;
                } else {
                    vue.$root.is_member = 1; // TODO: check if this is needed
                    vue.$root.community.isMember = 1;
                    vue.title = 'Successfully Joined';
                    vue.message = 'You have successfully joined the ' + vue.communityName + ' community\n';
                    if (typeof data.joined != 'undefined' && data.joined) {
                        vue.message += 'and liked this post';
                    }
                    vue.cta = false;
                    // TODO: update cached feed and explore sections after becoming a member - for now just delete and redirect
                    // remove feed and explore cache to they now refactor correctly on reload
                    sessionStorage.removeItem('feed');
                    sessionStorage.removeItem('explore');
                    sessionStorage.removeItem('c' + communityId);
                }
            },
            (response) => {
                vue.title = 'Error Joining';
                if (response.status === 403) {
                    vue.message = 'You do not have permission to complete this request.';
                } else {
                    vue.message = 'An unknown error occurred when joining.';
                }
                console.log('Errored:');
                console.log(response);
            }
        );
    },
    like(vue, confirmed, cardId, card) {
        let dyadeyId;
        let parameters = {};
        let communityId;
        let communityName;
        let communityThumbnail;
        if (confirmed) {
            parameters.params.c = 1;
        }
        if (typeof card != 'undefined') {
            dyadeyId = card.dyadey_id;
            communityId = card.community_id;
            communityName = card.community_name;
            communityThumbnail = card.community_thumbnail;
        } else if (typeof cardId != 'undefined') {
            dyadeyId = cardId;
            let communityImg = document.querySelector('article[data-did="' + cardId + '"] .cards__default-community-icon img');
            communityId = communityImg.src.replace(/.+\/communities\/([^\/]+)\/.+/, '$1');
            communityThumbnail = communityImg.src.replace(/.+\/communities\/.+\//, '');
        } else if (vue.$el.id === 'vue-social') {
            dyadeyId = parseInt(vue.$el.children[0].dataset.did);
            communityId = vue.$root.community.id;
        } else {
            dyadeyId = parseInt(vue.$el.dataset.did);
            communityId = vue.$root.community.id;
        }
        vue.$http.get('/like/' + dyadeyId, parameters).then(response => {
            let data = response.data;
            // user not logged in
            if (data.rU === false) {
                //vue.showMessage = true;
                //this.message.title = 'You are not registered/logged in';
                //this.message.content = 'Please login or register to like a post';
                document.body.classList.add("faded-join-convo"); // the 3 lines above was the old code - we now just slide out the join convo panel
                // user not member of post's community
            } else if (data.m === false) {
                let modalData = {
                    cta: '/like/' + dyadeyId + '?c=1',
                    buttonText: 'Join & Like',
                    message: 'You are currently not a member of this community - please click below to join the community and like this post',
                    communityId: communityId,
                    communityName: communityName,
                    communityThumbnail: communityThumbnail,
                    title: 'Join ' + communityName
                };
                vue.$root.$emit('bus-show-community-modal', modalData);
            } else if (data.u === true) {
                vue.postLikes -= 1;
                vue.postLiked = false;
                // on community page decrement likes
                if (typeof vue.card != 'undefined') {
                    vue.card.likes -= 1;
                    vue.card.liked = false;
                }
                // user successfully liked and joined post's community (if needed)
            } else if (data.m === true && data.s === true) {
                if (typeof data.cN !== 'undefined') {
                    let modalData = {
                        cta: false,
                        message: 'You have successfully joined the <strong>' + data.cN + '</strong> community and liked this post',
                        communityId: communityId,
                        communityThumbnail: communityThumbnail
                    };
                    vue.$root.$emit('bus-show-community-modal', modalData);
                }
                // increase like counter
                vue.postLikes += 1;
                // set liked class on heart icon to show it solid red
                vue.postLiked = true;
                // on community page increment likes
                if (typeof vue.card != 'undefined') {
                    vue.card.likes += 1;
                    vue.card.liked = true;
                }
            }
            // update sessionStorage versions of card
            let commData = JSON.parse(sessionStorage.getItem('c' + communityId));
            if (commData != null && commData.length > 0 && typeof commData[0].cards != 'undefined') {
                for (let i = 0; i < commData[0].cards.length; i++) {
                    if (commData[0].cards[i].dyadey_id === dyadeyId) {
                        commData[0].cards[i].liked = vue.postLiked;
                        commData[0].cards[i].likes = vue.postLikes;
                        sessionStorage.setItem('c' + communityId, JSON.stringify(commData));
                        break;
                    }
                }
            }
            let feedData = JSON.parse(sessionStorage.getItem('feed'));
            if (feedData != null && feedData.length > 0 && typeof feedData[0].cards != 'undefined') {
                for (let i = 0; i < feedData[0].cards.length; i++) {
                    if (feedData[0].cards[i].dyadey_id === dyadeyId) {
                        feedData[0].cards[i].liked = vue.postLiked;
                        feedData[0].cards[i].likes = vue.postLikes;
                        sessionStorage.setItem('feed', JSON.stringify(feedData));
                        break;
                    }
                }
            }
        }, response => {
            console.log('Errored:');
            console.log(response);
        });
    },
    follow(vue, userId, hideButtons= true) {
        vue.$http.post('/api/v1/follow', { profileId: userId }).then(response => {
            if (response.body.error === false && hideButtons) {
                let followingButtons = document.getElementsByClassName('c-btn--following');
                for (let i = 0; i < followingButtons.length; i++) {
                    followingButtons[i].classList.remove('u-hidden');
                }
                let followButtons = document.getElementsByClassName('c-btn--follow');
                for (let i = 0; i < followButtons.length; i++) {
                    followButtons[i].classList.add('u-hidden');
                }
                window.location.reload();
            }
        }, response => {
            console.log('Errored:');
            console.log(response);
        });
    },
    unfollow(vue, userId, hideButtons = true) {
        vue.$http.post('/api/v1/unfollow', { followedId: userId }).then(response => {
            if (response.body.error === false && hideButtons) {
                let followingButtons = document.getElementsByClassName('c-btn--following');
                for (let i = 0; i < followingButtons.length; i++) {
                    followingButtons[i].classList.add('u-hidden');
                }
                let followButtons = document.getElementsByClassName('c-btn--follow');
                for (let i = 0; i < followButtons.length; i++) {
                    followButtons[i].classList.remove('u-hidden');
                }
            }
            window.location.reload();
        }, response => {
            console.log('Errored:');
            console.log(response);
        });
    },
    copyLink(event) {
        let text = location.protocol + '//' + location.host + event.target.dataset.link;
        navigator.clipboard.writeText(text);
    },
    noop() {

    },
    urlBase64ToUint8Array(base64String) {
        let padding = '='.repeat((4 - base64String.length % 4) % 4);
        let base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        let rawData = window.atob(base64);
        let outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    },
    subscribeToNotifications() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            return navigator.serviceWorker.getRegistration(this.swNotificationsUrl)
                .then((registration) => {
                    if (registration) {
                        return this.requestNotificationPermission().then(() => {
                            return this.subscribeUserToPush().then((subscription) => {
                                return subscription;
                            });
                        });
                    } else {
                        return navigator.serviceWorker.register(this.swNotificationsUrl)
                            .then((registration) => {
                                console.log('Service worker successfully registered.');
                                //let swRegistration = registration;
                                return this.requestNotificationPermission().then(() => {
                                    return this.subscribeUserToPush().then((subscription) => {
                                        return subscription;
                                    });
                                });
                            })
                            .catch((err) => {
                                console.error('Unable to register service worker.', err);
                            });
                    }

                })
                .catch((err) => {
                    console.error('Unable to register service worker.', err);
                });
        } else {
            console.warn('Push messaging is not supported');
        }
    },
    requestNotificationPermission() {
        return new Promise((resolve, reject) => {
            const permissionResult = Notification.requestPermission((result) => {
                resolve(result);
            });
            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        })
            .then((permissionResult) => {
                if (permissionResult !== 'granted') {
                    throw new Error('We weren\'t granted permission.');
                } else {
                    console.log('notifications permitted');
                }
            });
    },
    subscribeUserToPush() {
        return navigator.serviceWorker.register(this.swNotificationsUrl)
            .then((registration) => {
                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array(this.swNotificationsAppcode)
                };
                return registration.pushManager.subscribe(subscribeOptions);
            })
            .then((pushSubscription) => {
                return JSON.stringify(pushSubscription);
            });
    },
    suggest(router, actionName) {
        sessionStorage.setItem('previous', JSON.stringify([window.location.href, actionName]));
        let suggestLocation = router.resolve({name: 'suggest'});
        window.location.href = suggestLocation.href;
    },
    cloudinaryUrl(width, height, img, vue) {
        if (!vue.$root.cloudinaryUrl) {
            return (img.substring(0, 1) !== '/' ? '/' : '') + img;
        }
        let dpr = vue != null ? ',dpr_' + vue.$root.$dpr : '';
        if (img.substring(0, 1) === '/') {
            img = img.substring(1);
        }
        let baseUrl = vue.$root.cloudinaryUrl + 'c_fill,f_auto,q_auto,g_center' + dpr + ',w_' + width + ',h_' + height + '/v1/';
        if (window.location.hostname.indexOf('.dey') !== -1) {
            baseUrl = '/';
        }
        return baseUrl + img;
    },
    showCookiePanel(vue) {
        vue.$root.$emit('bus-show-cookie-panel');
    },
    showLoginPanel(vue, invalid) {
        vue.$root.$emit('show-login-panel', invalid);
    },
    showMemberPanel(vue, communityName = null) {
        vue.$root.$emit('show-member-panel', communityName);
    },
    showSignupPanel(vue) {
        vue.$root.$emit('show-signup-panel');
    },
    showResetPanel(vue) {
        vue.$root.$emit('show-reset-panel');
    },
    async getCommunities(vue, page, search, userId) {
        // check sessionStorage cache
        let sessionKey = 'comms-' + page + '-' + search + '-' + userId;
        if (sessionStorage.getItem(sessionKey)) {
            return JSON.parse(sessionStorage.getItem(sessionKey));
        }
        let parameters = { query: search, page: page };
        let response = await vue.$http.post('/api/v1/user/' + userId + '/communities', parameters);
        for (let i = 0; i < response.data.results.length; i++) {
            response.data.results[i].image = response.data.results[i].image != null ?
                vue.$root.$children[0].applyDPR(response.data.results[i].image.replace(/,h_\d+/, ',h_112').replace(/,w_\d+/, ',w_112')) :
                null;
        }
        sessionStorage.setItem(sessionKey, JSON.stringify(response.data.results));
        return response.data.results;
    },
    contextMenu(vue, clickOutside) {
        if (clickOutside === true) {
            // nothing to do if already hidden
            if (this.showContextMenu === false) {
                return this.showContextMenu;
            }
            this.showContextMenu = false;
        } else {
            this.showContextMenu = !this.showContextMenu;
        }
        if (this.showContextMenu) {
            vue.$root.$emit('fade-page');
        } else {
            vue.$root.$emit('unfade-page');
        }
        return this.showContextMenu;
    },
    getMediaSize() {
        let mediaSize = 'xl';
        if (window.matchMedia("(max-width: 375px)").matches) {
            mediaSize = 'xs';
        } else if (window.matchMedia("(max-width: 479px)").matches) {
            mediaSize = 's';
        } else if (window.matchMedia("(max-width: 768px)").matches) {
            mediaSize = 'm';
        } else if (window.matchMedia("(max-width: 1020px)").matches) {
            mediaSize = 'l';
        }
        return mediaSize;
    },
    isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    },
    getHash(communityHashtags = false) {
        let reservedHashtags = ['join', 'login', 'invalid'];
        let hashTag = window.location.hash.substring(1);
        if (communityHashtags === true && reservedHashtags.includes(hashTag)) {
            return '';
        }
        return window.location.hash.substring(1);
    },
    highlightHashtags(content, communityUrl) {
        if (content == null) {
            return;
        }
        let re = /#([-_a-z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]{2,})/gi
        // hashtags cannot contain only digits so remove link from these
        let reInvalidHashtag = /<a href="\/.+#\d+">(#\d+)<\/a>/gi;
        return content.replace(re, '<a href="/' + communityUrl + '#$1">#$1</a>').replace(reInvalidHashtag, '$1');
    },
    setWithExpiry(key, value) {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + this.localStorageTTL,
        }
        localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
        const itemStr = localStorage.getItem(key);
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    },
    getWithSlidingExpiry(key) {
        const itemStr = localStorage.getItem(key);
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key);
            return null;
        }
        // Update the expiry time
        item.expiry = now.getTime() + this.localStorageTTL;
        localStorage.setItem(key, JSON.stringify(item));
        return item.value;
    },
    removeDrafts() {
        const cacheKey = this.localStorageCacheKeys.drafts;
        localStorage.removeItem(cacheKey);
    }
};
