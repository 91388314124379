var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("img", {
            staticClass: "menu__notifications--loader",
            attrs: {
              src: "/img/ui/icon-loader-eclipse.svg",
              alt: "loading",
              width: "50",
              height: "50",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "menu__header" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "40",
              height: "40",
              viewBox: "0 0 40 40",
              fill: "none",
            },
          },
          [
            _c("circle", {
              attrs: { cx: "20", cy: "20", r: "20", fill: "white" },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M22.9675 28.5176C22.7077 28.9682 22.3338 29.3426 21.8835 29.6028C21.4331 29.863 20.9222 30 20.402 30C19.8819 30 19.3709 29.863 18.9206 29.6028C18.4702 29.3426 18.0963 28.9682 17.8365 28.5176",
                fill: "white",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M22.9675 28.5176C22.7077 28.9682 22.3338 29.3426 21.8835 29.6028C21.4331 29.863 20.9222 30 20.402 30C19.8819 30 19.3709 29.863 18.9206 29.6028C18.4702 29.3426 18.0963 28.9682 17.8365 28.5176",
                stroke: "black",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M28.6899 25.1511H12C12.8815 23.3611 13.284 21.3731 13.1683 19.3811L13.0729 17.7979C12.9483 16.1603 13.2783 14.52 14.0266 13.058C15.3475 10.6022 17.6889 9 20.3211 9C22.9534 9 25.2947 10.6022 26.6156 13.058C27.3639 14.52 27.694 16.1603 27.5693 17.7979L27.4787 19.3334C27.3593 21.3404 27.7601 23.3444 28.6422 25.1511H28.6899Z",
                fill: "white",
                stroke: "black",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("p", [_vm._v("Notifications")]),
      ]),
      _vm._v(" "),
      _vm.loading === false
        ? _c("div", { staticClass: "menu__notifications--mark-as-read" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.markAllRead.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Mark all as read")]
            ),
            _vm._v(" "),
            _vm.loadingMarkRead
              ? _c("img", {
                  staticClass: "menu__notifications--mark-as-read--loader",
                  attrs: {
                    src: "/img/ui/icon-loader-eclipse.svg",
                    alt: "loading",
                    width: "26",
                    height: "26",
                  },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.notifications, function (notification, index) {
        return _c(
          "div",
          { staticClass: "menu__notifications" },
          [
            notification.type === _vm.NOTIFICATION_TYPES.FOLLOWER_ADDED
              ? _c(
                  "div",
                  {
                    class: [
                      "menu__notification",
                      notification.read === false ? "unread" : "",
                    ],
                  },
                  [
                    _c("div", { staticClass: "menu__notification-img" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.$simpleStore.cloudinaryUrl(
                            80,
                            80,
                            notification.notifierAvatar,
                            _vm.$root
                          ),
                          width: "40",
                          height: "40",
                          alt: "User image",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menu__notification-details" }, [
                      _c("p", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/u/" + notification.notifiers[0].username,
                            },
                          },
                          [_vm._v(_vm._s(notification.notifiers[0].name))]
                        ),
                        _vm._v(
                          " started following you\n                        "
                        ),
                        _c("span", [_vm._v(_vm._s(notification.when))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "c-panel__notification-btn",
                        on: {
                          click: function ($event) {
                            return _vm.handleClick($event, index)
                          },
                        },
                      },
                      [
                        notification.isFollowing === false
                          ? _c(
                              "button",
                              {
                                staticClass: "button__red",
                                attrs: {
                                  type: "button",
                                  "data-method": "follow",
                                  "data-parameter":
                                    notification.notifiers[0].user_id,
                                },
                              },
                              [_vm._v("Follow")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        notification.isFollowing === true
                          ? _c(
                              "button",
                              {
                                staticClass: "button__blue",
                                attrs: {
                                  type: "button",
                                  "data-method": "unfollow",
                                  "data-parameter":
                                    notification.notifiers[0].user_id,
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "36",
                                      height: "18",
                                      viewBox: "0 0 36 18",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M20 16.6772C20.4017 15.1406 21.322 13.7725 22.6191 12.7837C23.9162 11.7948 25.5181 11.2401 27.1782 11.2049C28.8383 11.1697 30.4646 11.656 31.8064 12.5888C33.1483 13.5217 34.1314 14.8494 34.6044 16.3675",
                                        stroke: "white",
                                        "stroke-width": "1.5",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M27.6678 8.17969H26.9352C26.0173 8.17969 25.137 7.83192 24.4879 7.21288C23.8389 6.59385 23.4742 5.75425 23.4742 4.8788V4.57667C23.4742 3.6281 23.8693 2.71836 24.5726 2.04762C25.2759 1.37687 26.2297 1.00006 27.2243 1.00006C27.7377 0.999565 28.2462 1.09566 28.7206 1.28281C29.1951 1.46997 29.6262 1.74452 29.9892 2.09077C30.3522 2.43701 30.6401 2.84814 30.8364 3.30063C31.0326 3.75311 31.1333 4.23806 31.1328 4.72772V4.8788C31.1328 5.75425 30.7682 6.59385 30.1191 7.21288C29.47 7.83192 28.5897 8.17969 27.6718 8.17969H27.6678Z",
                                        stroke: "white",
                                        "stroke-width": "1.5",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M1 9.48387L5.30769 14L13 4",
                                        stroke: "white",
                                        "stroke-width": "1.5",
                                        "stroke-linecap": "round",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : notification.type === _vm.NOTIFICATION_TYPES.LIKE_ADDED
              ? _c(
                  "div",
                  {
                    class: [
                      "menu__notification",
                      notification.read === false ? "unread" : "",
                    ],
                  },
                  [
                    _c("div", { staticClass: "menu__notification-img" }, [
                      _c(
                        "div",
                        { staticClass: "menu__notification-img--two" },
                        _vm._l(notification.notifiers, function (notifier) {
                          return _c("img", {
                            attrs: {
                              src: _vm.$simpleStore.cloudinaryUrl(
                                64,
                                64,
                                notifier.avatar,
                                _vm.$root
                              ),
                              width: "32",
                              height: "32",
                              alt: "User image",
                            },
                          })
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "logo",
                        attrs: {
                          src:
                            "/img/communities/" +
                            notification.communityId +
                            "/" +
                            notification.communityThumbnail,
                          height: "20",
                          width: "20",
                          alt: notification.communityName,
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menu__notification-details" }, [
                      _c(
                        "p",
                        [
                          _vm._l(
                            notification.notifiers,
                            function (notifier, index) {
                              return [
                                index > 0 && notification.notifiers.length === 2
                                  ? [
                                      _vm._v(
                                        "\n                                and\n                            "
                                      ),
                                    ]
                                  : index === 1 &&
                                    notification.notifiers.length > 2
                                  ? [
                                      _vm._v(
                                        "\n                                ,\n                            "
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "/u/" + notifier.username },
                                  },
                                  [_vm._v(_vm._s(notifier.name))]
                                ),
                              ]
                            }
                          ),
                          _vm._v(
                            "\n                        likes your post in\n                        "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "/" + notification.communityUrl },
                            },
                            [_vm._v(_vm._s(notification.communityName))]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(notification.when))]),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    notification.thumbnail != null
                      ? _c(
                          "div",
                          { staticClass: "menu__notification-content" },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.$simpleStore.cloudinaryUrl(
                                  100,
                                  100,
                                  notification.thumbnail,
                                  _vm.$root
                                ),
                                height: "50",
                                width: "50",
                                alt: "Pic",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : notification.type === _vm.NOTIFICATION_TYPES.UGC_ADDED
              ? [
                  _c(
                    "div",
                    {
                      class: [
                        "menu__notification",
                        notification.read === false ? "unread" : "",
                      ],
                    },
                    [
                      _c("div", { staticClass: "menu__notification-img" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.$simpleStore.cloudinaryUrl(
                              40,
                              40,
                              notification.notifierAvatar,
                              _vm.$root
                            ),
                            width: "40",
                            height: "40",
                            alt: "User image",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "menu__notification-details" }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/u/" + notification.notifiers[0].username,
                              },
                            },
                            [_vm._v(_vm._s(notification.notifiers[0].name))]
                          ),
                          _vm._v(
                            " posted in the\n                            "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "/" + notification.communityUrl },
                            },
                            [_vm._v(_vm._s(notification.communityName))]
                          ),
                          _vm._v(" community\n                            "),
                          _c("span", [_vm._v(_vm._s(notification.when))]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "menu__notification feature" }, [
                    _c("div", { staticClass: "menu__notification-details" }, [
                      _c("p", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(notification.article.title) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    notification.article.thumbnail != null
                      ? _c(
                          "div",
                          { staticClass: "menu__notification-content" },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.$simpleStore.cloudinaryUrl(
                                  100,
                                  100,
                                  notification.article.thumbnail,
                                  _vm.$root
                                ),
                                height: "50",
                                alt: "Pic",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }