import Vue from 'vue';
import VueRouter from  'vue-router';

const HomeTabs = () => import(/* webpackChunkName: "home" */'../components/home/HomeTabs');
const Index = () => import(/* webpackChunkName: "home" */'../components/home/Index');
const HotCommunities = () => import(/* webpackChunkName: "home" */'../components/home/HotCommunities');
const Tour = () => import(/* webpackChunkName: "tours" */'../components/Tour');
const UserPanels = () => import('../components/UserPanels');
const IndexCommunity = () => import(/* webpackChunkName: "community" */'../components/community/IndexCommunity');
const Communities = () => import(/* webpackChunkName: "communities" */'../components/community/Communities');
const CommunitiesHotPage = () => import(/* webpackChunkName: "communities" */'../components/community/CommunitiesHot');
const CommunitiesCards = () => import(/* webpackChunkName: "communities" */'../components/community/CommunityCards');
const CommunityInvites = () => import(/* webpackChunkName: "communities" */'../components/community/CommunityInvites');
const CommunitySuggest = () => import(/* webpackChunkName: "communities" */'../components/community/CommunitySuggest');
const IndexPost = () => import(/* webpackChunkName: "post" */'../components/post/IndexPost');
const PostContent = () => import(/* webpackChunkName: "post" */'../components/post/PostContent');
const IndexSearch = () => import(/* webpackChunkName: "search" */'../components/search/Index');
const SearchCards = () => import(/* webpackChunkName: "search" */'../components/community/CommunityCards');
const SearchCommunities = () => import(/* webpackChunkName: "search" */'../components/community/CommunitySearchResults');
const Hashtags = () => import(/* webpackChunkName: "search" */'../components/community/CommunityHashtags');
const SearchMembers = () => import(/* webpackChunkName: "search" */'../components/community/CommunityMembers');
const IndexProfile = () => import(/* webpackChunkName: "profile" */'../components/profile/IndexProfile');
const ProfileCommunities = () => import(/* webpackChunkName: "profile" */'../components/profile/Communities');
const ProfilePosts = () => import(/* webpackChunkName: "profile" */'../components/profile/ProfilePosts');
const ProfileLiked = () => import(/* webpackChunkName: "profile" */'../components/profile/ProfileLiked');
const ProfileFollowing = () => import(/* webpackChunkName: "profile" */'../components/profile/ProfileFollowing');
const ProfileFollowers = () => import(/* webpackChunkName: "profile" */'../components/profile/ProfileFollowers');
const ProfileDrafts = () => import(/* webpackChunkName: "profile" */'../components/profile/ProfileDrafts');
const IndexSettings = () => import(/* webpackChunkName: "profile" */'../components/settings/Index');
const SettingsProfile = () => import(/* webpackChunkName: "settings" */'../components/settings/SettingsProfile');
const SettingsEmail = () => import(/* webpackChunkName: "settings" */'../components/settings/SettingsEmail');
const SettingsInterests = () => import(/* webpackChunkName: "settings" */'../components/settings/SettingsInterests');
const IndexUgc = () => import(/* webpackChunkName: "ugc" */'../components/ugc/IndexUgc');
const Editor = () => import(/* webpackChunkName: "ugc" */'../components/ugc/Editor');
const ContentPage = () => import(/* webpackChunkName: "content" */'../components/ContentPage');
const ContactForm = () => import(/* webpackChunkName: "contact" */'../components/ContactForm');
const Notifications = () => import(/* webpackChunkName: "contact" */'../components/Notifications');

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        alias: '/explore',
        name: 'home',
        components: {
            index: Index,
            userPanels: UserPanels,
            tour: Tour
        },
        children: [
            {
                path: '',
                components: {
                    homeTabs: HomeTabs,
                    hotCommunities: HotCommunities
                }
            }
        ]
    },
    {
        path: '/communities',
        components: {
            index: Communities,
            userPanels: UserPanels
        }
    },
    {
        path: '/hot',
        components: {
            index: CommunitiesHotPage,
            userPanels: UserPanels
        }
    },
    {
        path: '/communities/suggest',
        name: 'suggest',
        components: {
            index: CommunitySuggest,
            userPanels: UserPanels
        }
    },
    {
        path: '/search',
        components: {
            index: IndexSearch,
            userPanels: UserPanels
        },
        children: [
            {
                path: '',
                name: 'contentSearch',
                components: {
                    results: SearchCards
                }
            },
            {
                path: 'communities',
                name: 'communitiesSearch',
                components: {
                    results: SearchCommunities
                }
            },
            {
                path: 'people',
                name: 'peopleSearch',
                components: {
                    results: SearchMembers
                }
            }
        ]
    },
    {
        path: '/(about|terms|privacy|cookies|faqs)',
        components: {
            index: ContentPage,
            userPanels: UserPanels
        }
    },
    {
        path: '/contact',
        components: {
            index: ContactForm,
            userPanels: UserPanels
        }
    },
    {
        path: '/notifications',
        components: {
            index: Notifications
        }
    },
    {
        path: '/settings',
        components: {
            index: IndexSettings
        },
        children: [
            {
                path: '',
                component: SettingsProfile
            },
            {
                path: 'email',
                component: SettingsEmail
            },
            {
                path: 'interests',
                component: SettingsInterests
            }
        ]
    },
    {
        path: '/u/:username',
        name: 'profile',
        components: {
            index: IndexProfile,
            userPanels: UserPanels
        },
        children: [
            {
                path: '',
                component: ProfileCommunities,
                name: 'userProfile'
            },
            {
                path: 'posts',
                component: ProfilePosts,
                name: 'userPosts'
            },
            {
                path: 'drafts',
                component: ProfileDrafts,
                name: 'userDrafts'
            },
            {
                path: 'liked',
                component: ProfileLiked,
                name: 'userLikes'
            },
            {
                path: ':community/posts',
                component: ProfilePosts,
                name: 'userCommunityPosts'
            },
            {
                path: ':community/likes',
                component: ProfileLiked,
                name: 'userCommunityLikes'
            },
            {
                path: 'following',
                alias: ':community/following',
                component: ProfileFollowing
            },
            {
                path: 'followers',
                alias: ':community/followers',
                component: ProfileFollowers
            },
            {
                path: 'drafts',
                alias: ':community/drafts',
                component: ProfileDrafts
            }
        ]
    },
    {
        path: '/post/create',
        alias: ['/post/create/:community', '/post/edit/:post_id'],
        components: {
            index: IndexUgc
        },
        children: [
            {
                path: '',
                components: {
                    editor: Editor
                }
            },
        ]
    },
    {
        path: '/:community',
        components: {
            index: IndexCommunity,
            userPanels: UserPanels,
            invitesPanel: CommunityInvites
        },
        children: [
            {
                path: '',
                name: 'content',
                component: CommunitiesCards
            },
            {
                path: 'official',
                name: 'communityOfficialPosts',
                component: CommunitiesCards
            },
            {
                path: 'articles',
                name: 'communityArticlePosts',
                component: CommunitiesCards
            },
            {
                path: 'hashtags',
                name: 'hashtags',
                component: Hashtags
            },
            {
                path: 'members',
                name: 'members',
                component: SearchMembers
            }
        ]
    },
    {
        path: '/:community/:dyadey_id',
        components: {
            index: IndexPost,
            userPanels: UserPanels,
            component: PostContent
        },
        children: [
            {
                name: 'post',
                path: '',
                component: PostContent
            },
            {
                name: 'postid',
                path: ':post_id',
                component: PostContent
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
