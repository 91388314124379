<template>
    <transition name="fade">
        <div v-if="showMenu" :class="['menu', { 'top': top }]">
            <div :class="['menu__content', scrollClass, minHeightClass, startHeightClass]">
                <ModalClose modal-class="menu__close" :close-event="closeEvent"></ModalClose>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
// TODO: 13/7/24 check for more places that are using the <Menu> component but still using the @toggle-menu attribute
import ModalClose from "../ModalClose.vue";

export default {
    components: {ModalClose},
    props: {
        top: {
            type: Boolean
        },
        closeEvent: {
            type: String,
            required: true
        },
        showMenu: {
            type: Boolean,
            required: true,
        },
        scrollClass: {
            type: String,
            default: null,
        },
        minHeightClass: {
            type: String,
            default: null,
        },
        startHeightClass: {
            type: String,
            default: null,
        }
    },
    mounted() {

    }
};
</script>
